























import {Component, Prop, Watch} from 'vue-property-decorator';
import SearchView from '@/components/SearchView.vue';
import Notifications from '@/components/mixins/Notifications';
import {Getter} from 'vuex-class';
import CustomerContactListItem from '@/components/business/customers/CustomerContactListItem.vue';
import {customersStore} from '@/store/modules/customers/CustomersStore';
import {profileStore} from '@/store/modules/profile';
import {mixins} from "vue-class-component";
import TextFieldEmail from "@/components/profile/privacy-security/TextFieldEmail.vue";
import EmptyState from "@/components/EmptyState.vue";

@Component({
  name: 'customers-list',
  components: {TextFieldEmail, CustomerContactListItem, SearchView, EmptyState}
})
export default class CustomersList extends mixins(Notifications) {
  @Prop({default: 240}) width?: number | string;
  @Prop({default: 240}) maxWidth?: number | string;
  @Prop() customers!: any[];
  @Prop() type!: string;

  @Getter selectedCustomer;
  srcCustomers?: any[] = [];
  emptyIcon: string = ''
  emptyTitle: string = ''
  emptyDescription: string = ''

  get style() {
    return {
      width: `${this.width}px`,
      maxWidth: `${this.maxWidth}px`
    };
  }

  get isEmpty() {
    return !this.srcCustomers?.length || this.srcCustomers?.length === 0
  }

  @Watch('customers')
  onCustomersChanged(after, before) {
    if (!before) {
      return;
    }
    this.srcCustomers = Object.assign([], after);
    if (!!this.selectedCustomer) {
      this.onCustomerSelected(this.selectedCustomer)
    }
  }

  inPersonal(customer: any): boolean {
    return customer.personal?.includes(profileStore.t2bUser!.id);
  }

  onCustomerSelected(customer) {
    const routeName = this.type === 'blocked' ? 'blocked-customer-profile' : 'customer-profile'
    this.$router.push({name: routeName, params: {customerId: customer.id, type: this.type}});
  }

  async onAddToPersonalContacts(customerId) {
    await customersStore.addToPersonalContacts(customerId)
    this.showInfo('Added to My contacts')

  }

  search(term) {
    if (!term) {
      this.srcCustomers = Object.assign([], this.customers);
      return;
    }
    const searchString = term.toLowerCase();
    const fFunc = (value) => value.fullName.toLowerCase().includes(searchString);
    this.srcCustomers = this.customers!!.filter(fFunc);
  }

  initEmpty() {
    const name = this.$route.name
    const subtype = this.$route.query.subtype;
    console.log('initEmpty', name)
    switch (name) {
      case 'customers':
        this.emptyIcon = require('../../../assets/_empty_states_contacts.svg')
        this.emptyTitle = 'No customer contacts yet'
        this.emptyDescription = 'New customers with whom<br>' +
            'you can initiate communication<br>' +
            'with will appear here'
        break
    }
  }

  updated() {
    this.initEmpty()
  }

  created() {
    this.srcCustomers = Object.assign([], this.customers);
    if (!!this.selectedCustomer) {
      this.onCustomerSelected(this.selectedCustomer)
    }
    this.initEmpty()
  }
};
