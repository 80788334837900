


























import {Component, Prop, Vue} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';

@Component({
  name: 'customer-contact-list-item',
  components: {AvatarWithStatus}
})
export default class CustomerContactListItem extends Vue {
  @Prop() item?: any;
  @Prop({default: false}) inPersonal?: boolean;
  @Prop({default: true}) border?: boolean;
  @Prop({default: false}) rounded?: boolean;
  @Prop({default: true}) listItem?: boolean;
  @Prop({default: 44}) avatarSize?: string | number;

  @Getter selectedCustomer;

  get sizes() {
    return {
      'x-small': 44,
      'small': 48,
      'medium': 56
    };
  }

  get selected(): boolean {
    return this.selectedCustomer?.id === this.item?.id;
  }

  get title(): string {
    return this.item?.fullName;
  }

  get subtitle(): string {
    return `ID:${this.item?.id}`;
  }

  get photoUrl(): string {
    return this.item?.photoUrl?.normal;
  }

  get online(): boolean {
    return !!this.item?.status?.online;
  }

  get optedOut() {
    return !this.item?.permissions?.contact
  }

  onItemSelected() {
    if (!this.selected) {
      this.$emit('contact-clicked', this.item);
    }
  }
};
